
export const KEYWORD_ID = 'keyword';
export const VOLUME_ID = 'volume';
export const SEARCHES_ID = 'searches';
export const SELLERS_ID = 'sellers';
export const UNITS_ID = 'units';
export const COMPETITORS_ID = 'competitors';
export const ALIBABA_ID = 'alibaba';
export const STANDARD_ID = 'standard';
export const AVERAGE_POINTS_ID = 'average-points';
export const ESTIMATED_PROFIT_ID = 'estimated-profit';
export const IMPROVE_ID = 'improve';
export const SMALL_STANDARD = 20;
export const LARGE_STANDARD = 25;
export const LARGE_BULKY = 45;

