import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { questions, sortableArrayByID } from './helpers'
import { AVERAGE_POINTS_ID, SEARCHES_ID, STANDARD_ID, VOLUME_ID } from './consts'
import { getFeedbackText } from './helpers'
import { sprintf } from 'sprintf-js'
import { removeLoadingKey } from '../../helpers'

export const ProductQualifierLoadingKeys = {
  send: "SEND"
}

const getValueAsText = (value, type) => {
  if (type === "standard") {
    return '0' === value ? 'Small Standard' : '1' === value ? 'Large Standard' : '2' === value ? 'Large & Bulky' : value;
  }
  return '0' === value ? 'No' : '1' === value ? 'Yes' : value;
}

const initialState = {
  loading: true,
  loadingKeys: [],
  emailDefaults: {
    answers: [],
    score: 0,
    result: ''
  }
}

export const sendProductQualifierResultsAsync = createAsyncThunk(
  'productQualifier/sendProductQualifierResults',
  async ({ score, answers, result, email, keywords }) => {
    await axios.post('/api/product-qualifier/send', {
      score,
      answers,
      result,
      email,
      keywords,
    })
    return { success: true }
  }
)

export const saveProductQualifierResultsAsync = async ({ score, answers }) =>
  axios.post('/api/product-qualifier/save', { score, answers })

export const productQualifierSlice = createSlice({
  name: 'productQualifier',
  initialState,
  reducers: {
    updateAnswers: (state, action) => {
      for (const key in action.payload.answers) {
        if (Object.hasOwnProperty.call(action.payload.answers, key)) {
          const value = action.payload.answers[key];
          if (key === AVERAGE_POINTS_ID) {
            const answerIndex = state.emailDefaults.answers.findIndex((a) => a.id === STANDARD_ID);
            if (answerIndex >= 0) {
              state.emailDefaults.answers[answerIndex].questionPoints = sprintf(questions[key], action.payload.selectedStandard);
              state.emailDefaults.answers[answerIndex].answerPoints = getValueAsText(value);
            }
          } else {
            const answerIndex = state.emailDefaults.answers.findIndex((a) => a.id === key);
            const indexToCheck = answerIndex >= 0 ? answerIndex : state.emailDefaults.answers.length - 1 >= 0 ? state.emailDefaults.answers.length : 0;
            state.emailDefaults.answers[indexToCheck] = {};
            state.emailDefaults.answers[indexToCheck].id = key;
            state.emailDefaults.answers[indexToCheck].question = questions[key];
            state.emailDefaults.answers[indexToCheck].answer = getValueAsText(value);
            if (key === STANDARD_ID) {
              state.emailDefaults.answers[indexToCheck].answer = getValueAsText(value, STANDARD_ID);
            }
            if (key === VOLUME_ID) {
              state.emailDefaults.answers[indexToCheck].feedback = getFeedbackText(state.emailDefaults.answers[indexToCheck].answer || 0);
            }
            if (key === SEARCHES_ID) {
              state.emailDefaults.answers[indexToCheck].question = sprintf(questions[key], action.payload.searchesValue);
            }
          }
        }
      }
      state.emailDefaults.score = action.payload.score
      state.emailDefaults.result = action.payload.result
      state.emailDefaults.answers = (state.emailDefaults.answers || []).sort((a, b) => {
        return sortableArrayByID.indexOf(a.id) - sortableArrayByID.indexOf(b.id);
      });
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendProductQualifierResultsAsync.pending, (state) => {
        state.loading = true;
        state.loadingKeys = [...state.loadingKeys, ProductQualifierLoadingKeys.send]
      })
      .addCase(sendProductQualifierResultsAsync.fulfilled, (state) => {
        state.loading = false;
        state.loadingKeys = removeLoadingKey(ProductQualifierLoadingKeys.send, state.loadingKeys)
      })
      .addCase(sendProductQualifierResultsAsync.rejected, (state) => {
        state.loading = false;
        state.loadingKeys = removeLoadingKey(ProductQualifierLoadingKeys.send, state.loadingKeys)
      })
  }
})

export const { updateAnswers } = productQualifierSlice.actions

export default productQualifierSlice.reducer
