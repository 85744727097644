import { ArrowNarrowLeftIcon, PencilIcon } from '@heroicons/react/outline'
import React, { useEffect, useState } from 'react'
import ReactTooltip from 'react-tooltip'
import { useSelector, useDispatch } from 'react-redux'
import { PERCENTAGE, DOLLAR, AMAZON_RETAIL, ADDITIONAL_FEES, UNITS, TOTAL_ADDITIONAL_FEES, TOTAL_ESTIMATED_PROFIT_PER_UNIT, TOTAL_ESTIMATED_BULK_ORDER_PROFIT } from './consts'
import { upsertProductAsync } from './powerProfitToolSlice'
import AdditionalFeesEditor from "./AdditionalFeesEditor"
import MetricItem from './MetricItem'
import Btn from './../../components/general/Btn'
import LockedFeature from "./../../components/general/LockedFeature"

const ViewMetrics = ({ product, closeHandler, setOpen, setEditingItem }) => {
  const { currentUser } = useSelector((state) => state.auth)
  const isBasic = currentUser?.role === 'basic'
  const dispatch = useDispatch()

  const [type, setType] = useState(product.typeAdditionalFees || PERCENTAGE);
  const [percentage, setPercentage] = useState(Number(product[ADDITIONAL_FEES]));
  // const [additionalFees, setAdditionalFees] = useState(Number(product[ADDITIONAL_FEES]))

  const [rangeTotalAdditionalFees, setRangeTotalAdditionalFees] = useState(product.totalAdditionalFees);
  const [rangeTotalEstimatedProfitPerUnit, setRangeTotalEstimatedProfitPerUnit] = useState(product.totalEstimatedProfitPerUnit);
  const [rangeTotalEstimatedBulkOrderProfit, setRangeTotalEstimatedBulkOrderProfit] = useState(product.totalEstimatedBulkOrderProfit);

  useEffect(() => {
    setPercentage(product[ADDITIONAL_FEES])
    setRangeTotalAdditionalFees(product[TOTAL_ADDITIONAL_FEES])
    setRangeTotalEstimatedProfitPerUnit(product[TOTAL_ESTIMATED_PROFIT_PER_UNIT])
    setRangeTotalEstimatedBulkOrderProfit(product[TOTAL_ESTIMATED_BULK_ORDER_PROFIT])
    if (product.typeAdditionalFees) {
      setType(product.typeAdditionalFees)
    }
  }, [
    product[ADDITIONAL_FEES], 
    product[TOTAL_ADDITIONAL_FEES], 
    product[TOTAL_ESTIMATED_PROFIT_PER_UNIT], 
    product[TOTAL_ESTIMATED_BULK_ORDER_PROFIT],
    product.typeAdditionalFees
  ]);

  const toggleType = React.useCallback(() => {
    setType(type => type === PERCENTAGE ? DOLLAR : PERCENTAGE);
  }, []);

  const dollarValue = Number(((Number(product[AMAZON_RETAIL]) * percentage) / 100).toFixed(2))

  useEffect(() => {
    setRangeTotalAdditionalFees(dollarValue * product[UNITS])
  }, [dollarValue])

  useEffect(() => {
    setRangeTotalEstimatedProfitPerUnit(((((product[UNITS] || 0) * (product[AMAZON_RETAIL] || 0)) - (product.totalManufacturingShippingCustomsCost || 0) - (product.totalAmazonFees || 0) - (rangeTotalAdditionalFees || 0))  / (product[UNITS] || 0)))
  }, [rangeTotalAdditionalFees])

  useEffect(() => {
    setRangeTotalEstimatedBulkOrderProfit(rangeTotalEstimatedProfitPerUnit * product[UNITS]);
  }, [rangeTotalEstimatedProfitPerUnit])

  useEffect(() => {
    ReactTooltip.rebuild()
  }, [])

  return (
    <div>
      <button
        className="flex items-center border-2 border-gray-400 px-4 py-2 rounded-lg mb-8"
        onClick={closeHandler}
      >
        <ArrowNarrowLeftIcon className="w-4 h-4 mr-2" />
        back to products
      </button>

      <div key={product.id} className="flex flex-col mb-10 gap-4">
        <div className="w-full rounded-md border py-6 px-6">
          <div className="flex justify-between items-center">
            <h1 className="text-4xl break-words">{product.title}</h1>
            <button
              onClick={() => {
                setEditingItem(product)
                setOpen(true)
              }}
              className="inline-flex px-4 py-2 rounded-md items-center text-purple-450 hover:bg-gray-100 transition-colors"
            >
              <PencilIcon className="h-5 w-5 mr-2" />
              edit
            </button>
          </div>
          <p className="mt-6">{product.description}</p>
          <div className='mt-6 mb-6'>
            <div className="mb-6 h-0.5 bg-gray-300 rounded-xl" />
            <h2 className="mt-6 mb-4 text-2xl text-gray-900 text-center">
              Additional Fees Per Unit
            </h2>
            {isBasic ?
              <div className="flex justify-center flex-col items-center">
                <LockedFeature />
              </div>
                :
              <>
                <AdditionalFeesEditor
                  product={product}
                  type={type} 
                  onTypeToggle={toggleType}
                  percentage={percentage}
                  onPercentageChange={setPercentage}
                  dollarValue={dollarValue}
                />
                <div className='mt-6 mb-6 flex justify-end'>
                  <Btn
                    onClick={() => dispatch(upsertProductAsync({ product: {...product, [ADDITIONAL_FEES]: percentage }, typeAdditionalFees: type }))}
                    row={true}
                  >
                    <span>Save</span>
                  </Btn>
                </div>
              </>
            }
          </div>
        </div>
        <div className="w-full rounded-md border flex flex-col items-center py-2 px-2 bg-gray-300">
          <div className="flex flex-row flex-wrap sm:flex-nowrap justify-center sm:justify-normal items-center gap-0.5 md:gap-1 xl:gap-4">
            <MetricItem
              itemName="Estimated Bulk Order Profit"
              itemValue={rangeTotalEstimatedBulkOrderProfit}
              fixedPoints={2}
              dollar={true}
              tooltip="estimated-profit-margin-tooltip"
              enableValueHighlighting={(Number(rangeTotalEstimatedBulkOrderProfit) || 0) !== 0} 
            />
            <div className="h-0 sm:h-12 border-r border-black"></div>
            <MetricItem
              itemName="Profit Margin"
              itemValue={product.profitMargin}
              percentage={true}
              tooltip="profit-margin-tooltip"
            />
            <div className="h-0 sm:h-12 border-r border-black"></div>
            <MetricItem
              itemName="Estimated Profit Per Unit"
              itemValue={rangeTotalEstimatedProfitPerUnit}
              fixedPoints={2}
              dollar={true} 
              tooltip="estimated-profit-per-unit-margin-tooltip"
              enableValueHighlighting={(Number(rangeTotalEstimatedProfitPerUnit) || 0) !== 0} 
            />
          </div>
          <div className="flex flex-row flex-wrap sm:flex-nowrap justify-center sm:justify-normal items-center gap-0.5 md:gap-2 xl:gap-4">
            <MetricItem
              itemName="Total Manufacturing Cost"
              itemValue={product.totalCost}
              fixedPoints={2}
              dollar={true}
              tooltip="total-manufacturing-cost-margin-tooltip"
            />
            <div className="h-0 sm:h-12 border-r border-black"></div>
            <MetricItem
              itemName="Total Manufacturing, Shipping, & Customs Cost"
              itemValue={product.totalManufacturingShippingCustomsCost}
              fixedPoints={2}
              dollar={true}
              tooltip="total-manufacturing-shipping-margin-tooltip"
            />
            <div className="h-0 sm:h-12 border-r border-black"></div>
            <MetricItem
              itemName="Total Cost Per Unit"
              itemValue={product.totalCostPerUnit}
              fixedPoints={2}
              dollar={true}
              tooltip="total-cost-per-unit-margin-tooltip"
            />
          </div>
          <div className="flex flex-row flex-wrap sm:flex-nowrap justify-center sm:justify-normal items-center gap-0.5 md:gap-2 xl:gap-4">
            <MetricItem
              itemName="Total Amazon Fulfillment Fees"
              itemValue={product.totalAmazonFees}
              fixedPoints={2}
              dollar={true}
              tooltip="amazon-fees-total-margin-tooltip"
            />
            {!isBasic && (
              <>
                <div className="h-0 sm:h-12 border-r border-black"></div>
                <MetricItem 
                  itemName="Total Additional Fees & Costs" 
                  itemValue={rangeTotalAdditionalFees} 
                  fixedPoints={2} 
                  dollar={true} 
                  tooltip="additional-fees-total-per-unit-margin-tooltip" 
                />
              </>
            )}
            <div className="h-0 sm:h-12 border-r border-black"></div>
            <MetricItem 
              itemName="Amazon Gross Profit Per Unit" 
              itemValue={product.amazonNet}
              isIncorrectValue={((Number(product.netProfitPerUnit) || 0) < 0 && (Number(product.amazonNet) || 0) > 0)}
              fixedPoints={2} 
              dollar={true} 
              tooltip="amazon-payout-per-unit-margin-tooltip" 
              // errorTooltip={((Number(product.netProfitPerUnit) || 0) < 0 && (Number(product.amazonNet) || 0) > 0) ? "amazon-payout-per-unit-error-margin-tooltip" : ""}
              // enableValueHighlighting={(Number(product.amazonNet) || 0) !== 0} 
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ViewMetrics
