import { ALIBABA_ID, AVERAGE_POINTS_ID, COMPETITORS_ID, ESTIMATED_PROFIT_ID, IMPROVE_ID, KEYWORD_ID, SEARCHES_ID, SELLERS_ID, STANDARD_ID, UNITS_ID, VOLUME_ID } from './consts'

export const getFeedbackText = (volume) => {
    if (volume > 0 && volume < 4000) {
      return "Rainmaker’s doesn’t recommend launching a product with the TSCRK less than 4,000 search volume.";
    }
    if (volume >= 4000 && volume < 25000) {
      return "You’re in the sweet spot! Most Rainmakers’ first products are within this range.";
    }
    if (volume >= 25000) {
      return "Products above 25,000 may require a higher budget.";
    }
    return null;
}

export const questions = {
    [KEYWORD_ID]: 'Are you looking at the top searched, closest-related keyword? (It should be very specific).',
    [VOLUME_ID]: 'What is the search volume?',
    [SEARCHES_ID]: 'Are there 3 other relevant keywords to the TSCRK with a monthly search volume that is at least %1$s searches per month?',
    [SELLERS_ID]: 'Do you see multiple FBA sellers on XRAY?',
    [UNITS_ID]: 'Are 3-5 of the top 10 listings selling 300+ units a month?',
    [COMPETITORS_ID]: 'Are 5-10 competitors with less than 100-150 reviews getting 150+ sales/month?',
    [ALIBABA_ID]: 'Are there less than 11 relevant competitors with over 1,000 reviews?',
    [STANDARD_ID]: 'What is your size tier?',
    [AVERAGE_POINTS_ID]: 'Is the average price point above $%1$s?',
    [ESTIMATED_PROFIT_ID]: 'Inside the Power Profit Tool, is the Estimated Profit Per Unit around $5 or more?',
    [IMPROVE_ID]: 'Can you improve / innovate the product?'
}

export const sortableArrayByID = [KEYWORD_ID, VOLUME_ID, SEARCHES_ID, SELLERS_ID, UNITS_ID, COMPETITORS_ID, ALIBABA_ID, STANDARD_ID, ESTIMATED_PROFIT_ID, IMPROVE_ID];