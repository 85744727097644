import React from 'react'
import SecondaryButton from '../../components/general/SecondaryButton'
import { QuestionMarkCircleIcon } from '@heroicons/react/solid'

const ProductQualifierQuestion = ({
  question,
  id,
  name,
  onChange,
  type = "default",
  onLearnMore,
  componentUnderQuestion
}) => {
  return (
    <fieldset>
      <div className="flex items-center">
        <legend className="text-base font-medium">{question}</legend>
        {onLearnMore && <LearnMoreButton onClick={onLearnMore} />}
      </div>
      {componentUnderQuestion}
      {type === "default" ? (
        <div className="mt-1 space-y-2">
          <div className="flex items-center">
            <input
              id={id + '-no'}
              name={name}
              type="radio"
              className="focus:ring-purple-450 h-4 w-4 text-purple-450 border-gray-300"
              value="0"
              onChange={onChange}
              required
            />
            <label htmlFor={id + '-no'} className="ml-3 block font-medium text-base">
              No
            </label>
          </div>
          <div className="flex items-center">
            <input
              id={id + '-yes'}
              name={name}
              type="radio"
              className="focus:ring-purple-450 h-4 w-4 text-purple-450 border-gray-300"
              value="1"
              onChange={onChange}
            />
            <label htmlFor={id + '-yes'} className="ml-3 block text-base font-medium">
              Yes
            </label>
          </div>
        </div>
      ) : (
        <div className="mt-1 space-y-2">
          <div className="flex items-center">
            <input
              id={id + '-small'}
              name={name}
              type="radio"
              className="focus:ring-purple-450 h-4 w-4 text-purple-450 border-gray-300"
              value="0"
              onChange={onChange}
              required
            />
            <label htmlFor={id + '-small'} className="ml-3 block font-medium text-base">
              Small Standard
            </label>
          </div>
          <div className="flex items-center">
            <input
              id={id + '-large-standard'}
              name={name}
              type="radio"
              className="focus:ring-purple-450 h-4 w-4 text-purple-450 border-gray-300"
              value="1"
              onChange={onChange}
            />
            <label htmlFor={id + '-large-standard'} className="ml-3 block text-base font-medium">
              Large Standard
            </label>
          </div>
          <div className="flex items-center">
            <input
              id={id + '-large-bulky'}
              name={name}
              type="radio"
              className="focus:ring-purple-450 h-4 w-4 text-purple-450 border-gray-300"
              value="2"
              onChange={onChange}
            />
            <label htmlFor={id + '-large-bulky'} className="ml-3 block text-base font-medium">
              Large & Bulky
            </label>
          </div>
        </div>
      )}
    </fieldset>
  )
}

export default ProductQualifierQuestion

export const LearnMoreButton = ({ onClick }) => {
  return (
    <SecondaryButton
      extraClasses="ml-2 text-gray-400 text-base"
      size="base"
      label={
        <span className="flex items-center" onClick={onClick}>
          <QuestionMarkCircleIcon className="h-5 w-5 mr-2" /> Learn More
        </span>
      }
    />
  )
}
