import React from 'react'
import { ExclamationCircleIcon } from '@heroicons/react/solid'
import FormFieldTooltip from '../components/general/FormFieldTooltip'
import classNames from 'classnames'
import { AdditionalFeesSwitcher } from '../features/power-profit-tool/AdditionalFeesSwitcher'
import { DOLLAR } from '../features/power-profit-tool/consts'

const FormField = ({
  label,
  field,
  type = 'text',
  error,
  onChange,
  maxValueForTypeDollar = '',
  isBasicRole = false,
  inputLabel = '',
  // enableMath = false,
  enableSwitcher = false,
  typeSwitcher,
  toggleSwitch,
  onUpdateMathClick,
  required = true,
  state,
  tooltip = null,
  disabled = false,
  ...rest
}) => {
  return (
    <div className="relative">
      <label
        htmlFor={field}
      >
        <div className={`${
          tooltip ? 'flex justify-between items-center' : 'block'
        } text-base font-medium text-gray-700 gap-1`}>
          <div className={`flex flex-col items-start`}>
            <span className={classNames([
              {
                "blur-sm filter": isBasicRole
              }
            ])}>{label}</span>
          </div>
          {enableSwitcher && (
            <AdditionalFeesSwitcher typeSwitcher={typeSwitcher} onChangeType={toggleSwitch} />
          )}
          <div>{tooltip && !isBasicRole && <FormFieldTooltip dataFor={tooltip} />}</div>
        </div>
      </label>
      <div className={`mt-1 relative -md shadow-sm ${isBasicRole ? "blur-sm filter" : ""}`}>
        {'textarea' === type ? (
          <textarea
            id={field}
            name={field}
            type={type}
            className={`appearance-none block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none sm:text-base ${
              error
                ? 'pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500'
                : 'border-gray-300 focus:ring-purple-450 focus:border-purple-450 placeholder-gray-400 '
            } ${disabled && 'text-gray-400 bg-gray-100'}`}
            value={state[field] ?? ''}
            onChange={onChange}
            required={required}
            disabled={disabled}
            {...rest}
          />
        ) : (
          <div className="relative">
            {inputLabel && (
              <span className="absolute top-1/2 -translate-y-1/2 transform left-2">{inputLabel}</span>
            )}
            <input
              id={field}
              name={field}
              type={type}
              className={`appearance-none block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none sm:text-base ${
                error
                  ? 'pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500'
                  : 'border-gray-300 focus:ring-purple-450 focus:border-purple-450 placeholder-gray-400 '
              } ${disabled && !isBasicRole && 'text-gray-400 bg-gray-100'} ${inputLabel && 'pl-6'}`}
              value={state[field] ?? ''}
              onChange={(event) => {
                onChange(event);
              }}
              required={required}
              disabled={disabled}
              {...rest}
            />
          </div>
        )}
        {typeSwitcher === DOLLAR && maxValueForTypeDollar && (
          <div className="absolute text-red-600">
            Maximum value is ${maxValueForTypeDollar}
          </div>
        )}
        {error && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
          </div>
        )}
      </div>
      {error && <p className="mt-2 text-sm text-red-600">{error}</p>}
    </div>
  )
}

export default FormField
