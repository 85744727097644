import React, { useState, useEffect } from 'react'
import PrimaryButton from '../../components/general/PrimaryButton'
import ProductQualifierQuestion, { LearnMoreButton } from './ProductQualifierQuestion'
import VideoModal from '../../components/general/modal/VideoModal'
import {
  ProductQualifierLoadingKeys,
  saveProductQualifierResultsAsync,
  sendProductQualifierResultsAsync,
  updateAnswers
} from './productQualifierSlice'
import { useDispatch, useSelector } from 'react-redux'
import { InlineLink } from '../power-profit-tool/PowerProfitTool'
import { ALIBABA_ID, AVERAGE_POINTS_ID, COMPETITORS_ID, ESTIMATED_PROFIT_ID, IMPROVE_ID, KEYWORD_ID, LARGE_BULKY, LARGE_STANDARD, SEARCHES_ID, SELLERS_ID, SMALL_STANDARD, STANDARD_ID, UNITS_ID, VOLUME_ID } from './consts'
import { sprintf } from 'sprintf-js'
import { arrayHasItems } from '../../helpers'
import { getFeedbackText, questions } from './helpers'
import { RefreshIcon } from "@heroicons/react/outline";
import { MainLoader } from '../../components/Loaders/MainLoader'
import { RefreshModal } from '../../components/general/modal/RefreshModal'

const isBadResult = (result) => result <= 6
const isGoodResult = (result) => result === 7
const isGreatResult = (result) => result >= 8
const getResultEmotion = (result) => {
  if (isBadResult(result)) return '👎👎'
  if (isGoodResult(result)) return '👍'
  if (isGreatResult(result)) return '👍👍'
  return null;
}

const ProductQualifier = () => {
  const dispatch = useDispatch()
  const [formValues, setFormValues] = useState({ volume: 0 })
  const [selectedStandard, setSelectedStandard] = useState(null);
  const [result, setResult] = useState(0)
  const [resultEmojis, setResultEmojis] = useState(null)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const currentUser = useSelector((state) => state.auth.currentUser)
  const isCourseOnly = currentUser?.role === 'course only'

  const feedbackVideoId = isGoodResult(result)
    ? '720873163'
    : isGreatResult(result)
    ? '720872716'
    : '720870577' // default to bad result

  const rateProduct = async () => {
    let total = 0;
    for (const formValue in formValues) {
      if (formValue === "standard") continue;
      if ('volume' === formValue) {
        if (formValues[formValue] > 4000 && formValues[formValue] < 20000) {
          total++
        }
      } else if ('1' === formValues[formValue]) {
        total++
      }
    }
    setResult(total);
    const res = getResultEmotion(total);
    setResultEmojis(res);
    await dispatch(updateAnswers({ 
      result: res, 
      score: total, 
      answers: formValues,
      searchesValue: getSearches(),
      selectedStandard
    }))
    saveProductQualifierResultsAsync({ score: result, answers: formValues });
    setEmailSent(false);
  };

  const onChange = (event) => {
    const { name, value } = event.target
    setFormValues((prevState) => ({ ...prevState, [name]: value }))
  }

  const onChangeStandard = (event) => {
    const { name, value } = event.target
    if (value === "0") setSelectedStandard(SMALL_STANDARD)
    if (value === "1") setSelectedStandard(LARGE_STANDARD)
    if (value === "2") setSelectedStandard(LARGE_BULKY)
    setFormValues((prevState) => ({ ...prevState, [name]: value }))
  }

  const getSearches = () => {
    if (formValues.volume < 4000) return (formValues.volume * 0.5).toFixed(0);
    if (formValues.volume >= 4000 && formValues.volume <= 10000) return (formValues.volume * 0.15).toFixed(0) 
    if (formValues) return (formValues.volume * 0.1).toFixed(0);
    return 0;
  }

  // "learn more" videos
  const [video, setVideo] = useState(null)

  // copy email
  const [copyEmail, setCopyEmail] = useState('');
  const [keyword, setKeywords] = useState('');
  const { emailDefaults, loadingKeys } = useSelector((state) => state.productQ)
  const [emailSent, setEmailSent] = useState(false)
  const [formKey, setFormKey] = useState(0)
  const [openRefreshModal, setOpenRefreshModal] = useState(false);

  const sendFormIsLoading = () => loadingKeys.includes(ProductQualifierLoadingKeys.send);
  const resultIsGood = () => result >= 7;
  
  const reset = () => {
    setFormValues({ volume: 0 });
    setSelectedStandard(null);
    setResult(0);
    setResultEmojis(null);
    setIsSubmitted(null);
    setCopyEmail("");
    setKeywords("");
    setEmailSent(false);
    setVideo(null);
    setFormKey((prevKey) => prevKey + 1);
    setOpenRefreshModal(false);
  };

  const getResultTitle = () => {
    if (resultIsGood()) return "Congratulations! Your idea could become an amazing product! Enter your email and TSCRK to get a copy of these results for easy reference.";
    return "Want a copy of this? Enter your email below:";
  };

  const handleVolumeChange = (event) => {
    setFormValues((prevState) => ({
      ...prevState,
      [VOLUME_ID]: event.target.value
    }))
  }

  return (
    <div className="h-screen py-6">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 md:px-8">
        <h1 className="text-2xl font-semibold ">Product Qualifier Tool</h1>
        {isCourseOnly && (
          <>
            <h2 className="mt-4 text-lg font-semibold ">Looking to Upgrade?</h2>
            <p className="text-md mb-4">
              You can unlock the full Rainmaker experience by{' '}
              <InlineLink to="https://rainmakerfamily.com/mastermind/?el=courseonly">
                upgrading to our Rainmaker Mastermind
              </InlineLink>
              .
            </p>
          </>
        )}
        <div className="h-full my-4 flex justify-center">
          <iframe
            src="https://player.vimeo.com/video/1043571968?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
            className="w-full h-full aspect-video-block max-w-2xl"
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
            allowFullScreen=""
            title="PQT | Intro to Tool"
          />
          <script src="https://player.vimeo.com/api/player.js"></script>
        </div>
        <p className="mt-4">
          Run any idea through the Product Qualifier to evaluate its potential before investing. 
          While this tool provides a framework to help identify promising product ideas, it does not guarantee success. 
          Click “Learn More” next to each question for detailed walkthrough videos, <a rel="noreferrer" className="border-b border-purple-450 text-purple-450" href="https://rainmakerlinks.com/PQTlessons" target="_blank">or visit this module</a> to master the step-by-step process of using the tool effectively.
        </p>
        <div className="pt-4 flex justify-between gap-4">
          <h3 className="text-2xl">Questions</h3>
          <button 
            className="p-1 bg-secondary flex items-center justify-center border-secondary border border-solid rounded-full hover:bg-secondary-100 text-white hover:text-primary cursor-pointer"
            onClick={() => setOpenRefreshModal(true)}
          >
            <RefreshIcon className="h-6 w-6" />
          </button>
        </div>
      </div>
      <div className="max-w-6xl mx-auto px-4 sm:px-6 md:px-8 overflow-hidden">
        <div>
          <form
            key={formKey}
            className="space-y-8 divide-y divide-gray-200"
            onSubmit={async (event) => {
              event.preventDefault()
              await rateProduct();
              setIsSubmitted(true);
            }}
          >
            <div className="space-y-8 divide-y divide-gray-200 text-lg">
              <div className="mt-2 flex flex-col gap-4">
                <div>
                  <ProductQualifierQuestion
                    onChange={onChange}
                    id={KEYWORD_ID}
                    name={KEYWORD_ID}
                    question={
                      <>
                        <span className="font-bold">1.</span> {questions[KEYWORD_ID]}
                      </>
                    }
                    onLearnMore={() => setVideo({ id: "1043572012", title: "PQT-Q1 | Finding Your TSCRK" })}
                  />
                </div>
                <fieldset>
                  <div className="flex items-center">
                    <legend className="text-md font-medium grow">
                      <span className="font-bold">2.</span> {questions[VOLUME_ID]}
                    </legend>
                    <LearnMoreButton onClick={() => setVideo({ id: "1043572029", title: "PQT-Q2 | What is the Search Volume?" })} />
                  </div>
                  <div className="mt-2 space-y-2">
                    <div className="w-full max-w-2xl relative">
                      <span
                        className="text-white bg-purple-450 text-xs rounded-full py-1.5 w-16 text-center absolute top-0"
                        style={{
                          left: `calc(${(formValues.volume / 50000) * 100}% - ${
                            27.5 + ((formValues.volume / 50000) * 100) / 10
                          }px)`
                        }}
                      >
                        {parseInt(formValues.volume).toLocaleString()}
                      </span>
                      <input
                        type="range"
                        value={formValues.volume || 0}
                        onChange={handleVolumeChange}
                        className="slider mt-10"
                        min="0"
                        max="50000"
                      />
                      <div className="flex justify-between text-base">
                        <span>0</span>
                        <span>50,000+</span>
                      </div>
                    </div>
                    {formValues.volume > 0 && (
                      <div className="text-md-4">
                        <h4 className="text-purple-450">Feedback</h4>
                        <p>{getFeedbackText(formValues.volume)}</p>
                      </div>
                    )}
                  </div>
                </fieldset>
                <div>
                  <ProductQualifierQuestion
                    onChange={onChange}
                    id={SEARCHES_ID}
                    name={SEARCHES_ID}
                    question={
                      <>
                        <span className="font-bold">3.</span> {sprintf(questions[SEARCHES_ID], getSearches())}
                      </>
                    }
                    onLearnMore={() => setVideo({ id: "1043572034", title: "PQT-Q3 | Are There Other Relevant Keywords?" })}
                  />
                </div>
                <div>
                  <ProductQualifierQuestion
                    onChange={onChange}
                    id={SELLERS_ID}
                    name={SELLERS_ID}
                    question={
                      <>
                        <span className="font-bold">4.</span> {questions[SELLERS_ID]}
                      </>
                    }
                    onLearnMore={() => setVideo({ id: "1043572038", title: "PQT-Q4 | Are There Multiple FBA Sellers?" })}
                  />
                </div>
                <div>
                  <ProductQualifierQuestion
                    onChange={onChange}
                    id={UNITS_ID}
                    name={UNITS_ID}
                    question={
                      <>
                        <span className="font-bold">5.</span> {questions[UNITS_ID]}
                      </>
                    }
                    onLearnMore={() => setVideo({ id: "1043572042", title: "PQT Q5 | 3-5 Sellers Selling 300 Plus" })}
                  />
                </div>
                <div>
                  <ProductQualifierQuestion
                    onChange={onChange}
                    id={COMPETITORS_ID}
                    name={COMPETITORS_ID}
                    question={
                      <>
                        <span className="font-bold">6.</span> {questions[COMPETITORS_ID]}
                      </>
                    }
                    onLearnMore={() => setVideo({ id: "1043572045", title: "PQT-Q6 | Are New Sellers Receiving Sales?" })}
                  />
                </div>
                <div>
                  <ProductQualifierQuestion
                    onChange={onChange}
                    id={ALIBABA_ID}
                    name={ALIBABA_ID}
                    question={
                      <>
                        <span className="font-bold">7.</span> {questions[ALIBABA_ID]}
                      </>
                    }
                    onLearnMore={() => setVideo({ id: "1043572053", title: "PQT-Q7 | Competitors With Over 1,000 reviews?" })}
                  />
                </div>
                <div>
                  <ProductQualifierQuestion
                    onChange={onChangeStandard}
                    id={STANDARD_ID}
                    type={"standard"}
                    name={STANDARD_ID}
                    question={
                      <>
                        <span className="font-bold">8.</span> {questions[STANDARD_ID]}
                      </>
                    }
                    onLearnMore={() => setVideo({ id: "1043572059", title: "PQT-Q8 | Estimating Your Size Tier" })}
                  />
                  {selectedStandard && (
                    <ProductQualifierQuestion
                      onChange={onChange}
                      name={AVERAGE_POINTS_ID}
                      id={AVERAGE_POINTS_ID}
                      question={
                        <div className="mt-4">
                          {sprintf(questions[AVERAGE_POINTS_ID], selectedStandard)}
                        </div>
                      }
                    />
                  )}
                </div>
                <div>
                  <ProductQualifierQuestion
                    onChange={onChange}
                    id={ESTIMATED_PROFIT_ID}
                    name={ESTIMATED_PROFIT_ID}
                    question={
                      <>
                        <span className="font-bold">9.</span> {questions[ESTIMATED_PROFIT_ID]}
                      </>
                    }
                    onLearnMore={() => setVideo({ id: "1043572062", title: "9-PQT-Q9 - PPT profit around 5" })}
                  />
                </div>
                <div>
                  <ProductQualifierQuestion
                    onChange={onChange}
                    id={IMPROVE_ID}
                    name={IMPROVE_ID}
                    question={
                      <>
                        <span className="font-bold">10.</span> {questions[IMPROVE_ID]}
                      </>
                    }
                    onLearnMore={() => setVideo({ id: "1043572075", title: "PQT-Q10 - Can You Innovate" })}
                  />
                </div>
              </div>
            </div>

            <div className="py-5">
              <div className="flex">
                <PrimaryButton type="submit" label="Rate My Product" extraClasses="uppercase text-sm py-1.5 px-2" />
              </div>
            </div>
          </form>

          {isSubmitted && (
            <>
              <div className="max-w-xl">
                <h1 className="text-2xl font-bold">Results:</h1>
                <span className="text-3xl text-purple-450 mt-2 block">
                  {result}/10 — {resultEmojis}
                </span>
                <div className="relative mt-2" style={{ paddingTop: '56.25%' }}>
                  <iframe
                    src={`https://player.vimeo.com/video/${feedbackVideoId}?h=d4e9b07569&amp;title=0&amp;byline=0&amp;portrait=0&amp;playsinline=0&amp;autopause=0&amp;app_id=122963`}
                    className="absolute inset-0 w-full h-full"
                    frameBorder="0"
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowFullScreen=""
                    title="Rainmaker App Welcome 2"
                    data-ready="true"
                  ></iframe>
                </div>
              </div>

              <div className="py-4 relative">
                {emailSent && (
                  <h2 className="text-lg mb-2 text-green-500">
                    The email has been sent successfully.
                  </h2>
                )}
                {sendFormIsLoading() && (
                  <div className="absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 h-full w-full z-50">
                    <MainLoader />
                  </div>
                )}
                {!emailSent && (
                  <>
                    <h2 className="text-2xl mb-4">
                      {getResultTitle()}
                    </h2>
                    <form
                      className={`${sendFormIsLoading() ? "opacity-70" : ""}`}
                      onSubmit={async (event) => {
                        event.preventDefault()
                        let answers = emailDefaults.answers
                        if (keyword && resultIsGood()) {
                          answers = appendKeywordToAnswers(emailDefaults.answers, keyword)
                        }
                        const action = await dispatch(
                          sendProductQualifierResultsAsync({
                            score: emailDefaults.score,
                            answers,
                            result: emailDefaults.result,
                            email: copyEmail,
                            keywords: keyword,
                          })
                        )
                        if (action.type.includes('fulfilled')) setEmailSent(true)
                      }}
                    >
                      <label htmlFor="email">
                        Rainmaker Email
                        <input
                          className={`appearance-none block w-full px-3 py-2 mt-1 mb-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-purple-450 focus:border-purple-450 sm:text-sm`}
                          type="email"
                          id="email"
                          required
                          disabled={sendFormIsLoading()}
                          placeholder="Rainmaker Email"
                          value={copyEmail}
                          onChange={(event) => {
                            setCopyEmail(event.target.value)
                          }}
                        />
                      </label>
                      <label htmlFor="tscrk">
                        TSCRK
                        <input
                          className={`appearance-none block w-full px-3 py-2 mt-1 mb-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-purple-450 focus:border-purple-450 sm:text-sm`}
                          type="text"
                          id="tscrk"
                          required
                          disabled={sendFormIsLoading()}
                          placeholder="Your top searched, closest-related keyword"
                          value={keyword}
                          onChange={(event) => {
                            setKeywords(event.target.value)
                          }}
                        />
                      </label>
                      <button
                        type="submit"
                        className="w-44 flex justify-center py-2 px-4 border mt-4 border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-purple-450 hover:bg-purple-450 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-450 uppercase"
                      >
                        Submit
                      </button>
                    </form>
                  </>
                )}
              </div>
            </>
          )}
          <div className="pb-8">
            <h3 className="text-2xl pb-4">Need more feedback?</h3>
            <ul className="flex flex-col space-y-4 ml-4">
              <li className="text-base">
                <span className="rounded-full bg-purple-450 px-2 py-0.5 text-white mr-2">1</span>
                If you’re a Hustle Mama, you can{' '}
                <a
                  className="border-b border-purple-450 text-purple-450"
                  href="https://rainmakerupgrades.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  book a 2nd eyes session here.
                </a>
              </li>
              <li className="text-base">
                <span className="rounded-full bg-purple-450 px-2 py-0.5 text-white mr-2">2</span>
                Ask for a product review on the next{' '}
                <a
                  className="border-b border-purple-450 text-purple-450"
                  href="https://rainmakercalendar.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Product Research Coaching Clinic.
                </a>
              </li>
            </ul>
          </div>
        </div>
        <VideoModal 
          open={!!video} 
          setOpen={setVideo} 
          videoId={video?.id} 
          videoTitle={video?.title}
        />
        <RefreshModal 
          open={openRefreshModal} 
          onClose={() => setOpenRefreshModal(false)}
          refresh={() => reset()}
        />
      </div>
    </div>
  )
}

function appendKeywordToAnswers(answers, keyword) {
  if (keyword && arrayHasItems(answers)) {
    const answersClone = answers.map((item) => {
      const currentItem = { ...item };
      if (currentItem.id === KEYWORD_ID && currentItem.answer === 'Yes') {
        currentItem.answer = `Yes - ${keyword}`
      }
      return currentItem
    })
    return answersClone;
  }
  return answers;
}

export default ProductQualifier
