import { LockClosedIcon } from '@heroicons/react/solid'

const LockedFeature = () => {
  return (
    <div className="relative w-60 h-32 flex items-center flex-col h-full">
      <div className="relative w-32 h-32">
        <div className="absolute inset-0 bg-grey rounded-lg shadow-lg filter blur"></div>
        <div className="absolute inset-0 flex items-center justify-center">
          <LockClosedIcon className="w-28 h-28 z-10 text-purple-450" />
        </div>
      </div>
      <a 
        className="text-purple-450 mt-4 text-center text-base cursor-pointer"
        href={'https://rainmakerfamily.com/mastermind/?el=rmapp'}
        target="_blank"
        rel="noopener noreferrer"
      >
        Click here to unlock this feature
      </a>
    </div>
  )
}

export default LockedFeature
